import { queryOptions } from "@tanstack/react-query";
import type ServiceLocator from "@services/locator/serviceLocator";

export const movieContentQuery = (
	serviceLocator: ServiceLocator,
	movieId: string,
) => {
	const contentService = serviceLocator.get("contentService");
	return queryOptions({
		queryKey: ["movieList", movieId],
		queryFn: () => contentService.getMovieContent(movieId),
		staleTime: 1000 * 60 * 60,
		enabled: !!movieId,
	});
};
