import { createFileRoute } from "@tanstack/react-router";
import { movieSearchSchema } from "@/schemas/movieSearchSchema";
import { filterListQuery } from "@/queries/filterListQuery";
import { DEFAULT_FILTER_SLUG } from "@/constants/movieConstants";
// import { paginatedMovieListQuery } from "@/queries/movieListQuery";

export const Route = createFileRoute("/movies/")({
	loaderDeps: ({ search: { filterId, page, pageSize } }) => ({
		filterId,
		page,
		pageSize,
	}),
	loader: async ({
		context: { serviceLocator, queryClient },
		preload,
		deps: { filterId },
	}) => {
		const filters = await queryClient.fetchQuery(
			filterListQuery(serviceLocator),
		);
		const defaultFilter = filters.find(
			(filter) => filter.slug === DEFAULT_FILTER_SLUG,
		);

		if (!preload) {
			serviceLocator
				.get("loggerService")
				.logEvent("movies page view", filterId ?? defaultFilter?.id);
		}

		// queryClient.prefetchQuery(
		// 	paginatedMovieListQuery(
		// 		serviceLocator,
		// 		page,
		// 		pageSize,
		// 		filterId ?? defaultFilter?.id,
		// 	),
		// );
	},
	validateSearch: movieSearchSchema,
});
