import { RouterProvider, createRouter } from "@tanstack/react-router";
import { ConfigProvider, Layout } from "antd";
import { routeTree } from "./routeTree.gen";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ServiceLocator, {
	ServiceLocatorProvider,
} from "@services/locator/serviceLocator";
import tsTheme from "@themes/ts_theme";
import { isAxiosError } from "axios";
import "./App.css";

export const serviceLocator = new ServiceLocator();
const authService = serviceLocator.get("authService");

const MAX_RETRIES = 1;
const HTTP_STATUS_TO_NOT_RETRY = [400, 403, 404];

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: (failureCount, error) => {
				if (failureCount >= MAX_RETRIES) {
					return false;
				}
				if (isAxiosError(error)) {
					if (error.response?.status === 401) {
						console.log("Auth expired, refreshing token and retrying...");
						authService.refresh().then(() => {
							console.log("Token refreshed, retrying...");
							queryClient.invalidateQueries();
						});
						return true;
					}
				}
				if (
					isAxiosError(error) &&
					HTTP_STATUS_TO_NOT_RETRY.includes(error.response?.status ?? 0)
				) {
					console.log(`Aborting retry due to ${error.response?.status} status`);
					return false;
				}
				return true;
			},
		},
	},
});

serviceLocator.constructContentService(queryClient);

// Set up a Router instance
const router = createRouter({
	routeTree,
	context: {
		queryClient,
		serviceLocator,
	},
	defaultPreload: "intent",
	// Since we're using React Query, we don't want loader calls to ever be stale
	// This will ensure that the loader is always called when the route is preloaded or visited
	defaultPreloadStaleTime: 0,
});

declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

function App() {
	return (
		<ServiceLocatorProvider serviceLocator={serviceLocator}>
			<ConfigProvider theme={tsTheme}>
				<Layout className="layout-wrapper">
					<QueryClientProvider client={queryClient}>
						<RouterProvider router={router} />
					</QueryClientProvider>
				</Layout>
			</ConfigProvider>
		</ServiceLocatorProvider>
	);
}

export default App;
