import * as Sentry from "@sentry/react";
import type { IMonitorService } from "./monitorTypes";
import type ConfigService from "@services/config/configService";

export default class MonitorService implements IMonitorService {
	dsn: string;
	environment: string;

	constructor(configService: ConfigService) {
		const { dsn, environment } = configService.sentry;
		this.dsn = dsn;
		this.environment = environment || "development";
		this.init();
	}

	private init() {
		Sentry.init({
			dsn: this.dsn,
			integrations: [
				Sentry.browserTracingIntegration(),
				Sentry.replayIntegration({
					maskAllText: false,
					blockAllMedia: false,
				}),
			],
			environment: this.environment,
			// Performance Monitoring
			tracesSampleRate: 1.0, // Capture 100% of the transactions
			tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
			// Session Replay
			replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
			replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur,
		});
	}

	public setUser(id: string, email: string) {
		Sentry.setUser({
			id: id,
			email: email,
			ip_address: "{{auto}}",
		});
	}
}
