import Bottle from "bottlejs";
import ConfigService from "@services/config/configService";
import MonitorService from "@services/monitor/monitorService";
import ContentService from "@services/content/contentService";
import AuthService from "@services/auth/authService";
import type { IConfigService } from "@services/config/configTypes";
import type { IMonitorService } from "@services/monitor/monitorTypes";
import type { IContentService } from "@services/content/contentTypes";
import { createContext, useContext } from "react";
import type { IAuthService } from "@services/auth/authTypes";
import LoggerService from "@services/logger/loggerService";
import type { ILoggerService } from "@services/logger/loggerTypes";
import type { ReactNode } from "react";
import type { QueryClient } from "@tanstack/react-query";

type InterfaceCheck<TInterface, TClass> = TClass extends TInterface
	? TClass
	: never;

type ServiceTypeMap = {
	configService: InterfaceCheck<IConfigService, ConfigService>;
	monitorService: InterfaceCheck<IMonitorService, MonitorService>;
	loggerService: InterfaceCheck<ILoggerService, LoggerService>;
	contentService: InterfaceCheck<IContentService, ContentService>;
	authService: InterfaceCheck<IAuthService, AuthService>;
};

export default class ServiceLocator {
	private locator: Bottle;

	constructor() {
		this.locator = new Bottle();
		this.locator.service("configService", ConfigService);
		this.locator.service("authService", AuthService, "configService");
		this.locator.service("monitorService", MonitorService, "configService");
		this.locator.service("loggerService", LoggerService, "configService");
		this.locator.service(
			"contentService",
			ContentService,
			"configService",
			"authService",
		);
	}

	public constructContentService(queryClient: QueryClient) {
		this.locator.factory("contentService", (container) => {
			const configService = container.configService;
			const authService = container.authService;
			return new ContentService(configService, authService, queryClient, this);
		});
	}

	public get<K extends keyof ServiceTypeMap>(name: K): ServiceTypeMap[K] {
		const service = this.locator.container[name];
		if (!service) {
			throw new Error(`Service ${name} not found.`);
		}
		return service as ServiceTypeMap[K];
	}
}

export const ServiceLocatorContext = createContext<ServiceLocator>(
	new ServiceLocator(),
);

export function ServiceLocatorProvider({
	children,
	serviceLocator,
}: { children: ReactNode; serviceLocator: ServiceLocator }) {
	return (
		<ServiceLocatorContext.Provider value={serviceLocator}>
			{children}
		</ServiceLocatorContext.Provider>
	);
}
export function useServiceLocator() {
	return useContext(ServiceLocatorContext);
}
