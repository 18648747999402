import { Layout } from "antd";
const { Footer } = Layout;

function FooterComponent() {
	return (
		<Footer className="footer">
			{process.env.NODE_ENV !== "production" && (
				<p>Currently running on: {import.meta.env.VITE_ENVIRONMENT}</p>
			)}
		</Footer>
	);
}

export default FooterComponent;
