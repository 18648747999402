import type { AxiosInstance } from "axios";
import type AuthService from "@services/auth/authService";
import type ConfigService from "@services/config/configService";
import type { IContentService } from "./contentTypes";
import { bannerResponseSchema } from "@schemas/bannerSchema";
import {
	type MovieContent,
	movieContentResponseSchema,
} from "@schemas/movieContentSchema";
import { filterSchema } from "@/schemas/filterListSchema";
import { filterListQuery } from "@/queries/filterListQuery";
import { elasticResponseSchema } from "@/schemas/elasticResponseSchema";
import type { MoviePage, MoviePageInfinite } from "@/schemas/movieListSchema";
import type { QueryClient } from "@tanstack/react-query";
import type ServiceLocator from "@services/locator/serviceLocator";

export default class ContentService implements IContentService {
	apiUrl: string;
	elasticUrl: string;
	tenantShortName: string;
	axios: AxiosInstance;
	queryClient: QueryClient;
	serviceLocator: ServiceLocator;

	constructor(
		configService: ConfigService,
		authService: AuthService,
		queryClient: QueryClient,
		serviceLocator: ServiceLocator,
	) {
		const { apiUrl, elasticUrl, tenantShortName } = configService.content;
		this.apiUrl = apiUrl;
		this.elasticUrl = elasticUrl;
		this.tenantShortName = tenantShortName;
		this.axios = authService.axios;
		this.queryClient = queryClient;
		this.serviceLocator = serviceLocator;
	}

	public async getHeroBanner() {
		const response = await this.axios.get(`${this.apiUrl}/banners/hero`);
		return bannerResponseSchema.parse(response.data).data.banners;
	}

	public async getListOfMoviesInfinite(
		filterId?: string,
		pageParam?: Array<number>,
	): Promise<MoviePageInfinite> {
		if (!filterId) return { movies: [], lastResultSort: [] };

		const filters = await this.queryClient.ensureQueryData(
			filterListQuery(this.serviceLocator),
		);

		const selectedFilter = filters.find((filter) => filter.id === filterId);

		if (!selectedFilter) return { movies: [], lastResultSort: [] };
		const response = await this.axios.post(
			`${this.elasticUrl}/${this.tenantShortName}_movies/_search`,
			{
				search_after: pageParam,
				size: 20,
				sort: [
					{
						[selectedFilter.sort || "publishedDate"]:
							selectedFilter.sortDirection || "desc",
					},
				],
				query: {
					bool: {
						must: [
							{
								query_string: {
									query: selectedFilter.query || "*",
								},
							},
						],
					},
				},
			},
		);
		const parsedResponse = elasticResponseSchema.parse(response.data);
		const hits = parsedResponse.hits.hits;
		const pageTransformation: MoviePageInfinite = {
			movies: hits.map((hit) => {
				return {
					...hit._source,
					id: hit._id,
				};
			}),
			lastResultSort: hits.length > 0 ? hits[hits.length - 1].sort : [],
		};
		return pageTransformation;
	}

	public async getListOfMoviesPaginated(
		page: number,
		pageSize: number,
		filterId?: string,
	): Promise<MoviePage> {
		if (!filterId) return { movies: [], total: 0 };

		const filters = await this.queryClient.ensureQueryData(
			filterListQuery(this.serviceLocator),
		);

		const selectedFilter = filters.find((filter) => filter.id === filterId);

		if (!selectedFilter) return { movies: [], total: 0 };
		const response = await this.axios.post(
			`${this.elasticUrl}/${this.tenantShortName}_movies/_search`,
			{
				from: (page - 1) * pageSize,
				size: pageSize,
				sort: [
					{
						[selectedFilter.sort || "publishedDate"]:
							selectedFilter.sortDirection || "desc",
					},
				],
				query: {
					bool: {
						must: [
							{
								query_string: {
									query: selectedFilter.query || "*",
								},
							},
						],
					},
				},
			},
		);

		const parsedResponse = elasticResponseSchema.parse(response.data);
		const hits = parsedResponse.hits.hits;
		const movieTransformation = {
			movies: hits.map((hit) => {
				return {
					...hit._source,
					id: hit._id,
				};
			}),
			total: parsedResponse.hits.total.value,
		};

		return movieTransformation;
	}

	public async getMovieContent(movieId: string): Promise<MovieContent> {
		const response = await this.axios.get(
			`${this.apiUrl}/movie/${movieId}/watch`,
		);
		return movieContentResponseSchema.parse(response.data).data;
	}

	public async getListOfFilters() {
		const response = await this.axios.get(
			`${this.elasticUrl}/f/ezFilters_v2/${this.tenantShortName}/all.json`,
		);
		return Object.keys(response.data).map((key: string) =>
			filterSchema.parse(response.data[key]),
		);
	}
}
