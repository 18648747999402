import Cookie from "js-cookie";
import type ConfigService from "@services/config/configService";
import type { IAuthService, User } from "./authTypes";
import { jwtDecode } from "jwt-decode";
import axios, { type AxiosInstance, type AxiosResponse } from "axios";

export default class AuthService implements IAuthService {
	loggedIn: boolean;
	authUrl: string;
	domain: string;
	user: User | undefined;
	axios: AxiosInstance;

	constructor(configService: ConfigService) {
		const token = this.getToken();
		this.loggedIn = !!token;
		this.authUrl = configService.auth.authUrl;
		this.domain = configService.auth.domain;
		if (!this.loggedIn) {
			this.refresh();
		} else {
			this.user = jwtDecode(token);
		}

		this.axios = axios;

		this.setRequestAuthInterceptor(token);
	}

	private setRequestAuthInterceptor(token: string) {
		if (!token) return;
		this.axios.interceptors.request.clear();
		this.axios.interceptors.request.use(
			(config) => {
				config.headers.Authorization = `Bearer ${token}`;
				return config;
			},
			(error) => Promise.reject(error),
		);
	}

	private redirectToLogin() {
		const redirectUrl = window.location.href.split("?")[0];
		this.setRedirectUrlCookie(redirectUrl);
		window.location.href = `${this.authUrl}/logout`;
	}

	private setToken(token: string) {
		this.setRequestAuthInterceptor(token);
		Cookie.set("access_token", token, { domain: `.${this.domain}` });
	}

	public getToken() {
		return Cookie.get("access_token") || "";
	}

	public async refresh() {
		const refreshToken = Cookie.get("refresh_token");
		if (refreshToken) {
			const response: AxiosResponse = await axios(`${this.authUrl}/refresh`, {
				method: "POST",
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
				},
				data: JSON.stringify({ refresh_token: refreshToken }),
			});
			this.setToken(response.data.access_token);
			return response;
		}
		this.redirectToLogin();
		return;
	}

	public setRedirectUrlCookie(path: string) {
		const expiresIn = new Date(new Date().getTime() + 10 * 60 * 1000);
		return Cookie.set("redirect_url", path, {
			domain: `.${this.domain}`,
			expires: expiresIn,
		});
	}
}
