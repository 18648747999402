import { createFileRoute } from "@tanstack/react-router";
import { movieContentQuery } from "@queries/movieContentQuery";

export const Route = createFileRoute("/movies/$movieId/")({
	loader: async ({
		context: { serviceLocator, queryClient },
		preload,
		params,
	}) => {
		if (!preload) {
			serviceLocator
				.get("loggerService")
				.logEvent(`movie view ${params.movieId}`);
		}
		queryClient.prefetchQuery(
			movieContentQuery(serviceLocator, params.movieId),
		);
	},
});
