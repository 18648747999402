import { theme as antDtheme } from "antd";
const theme = {
	token: {
		borderRadius: 2,
		wireframe: false,
		colorPrimary: "#09b83e",
		colorInfo: "#09b83e",
		colorError: "#e4595b",
		colorSuccess: "#a9ec89",
		colorTextBase: "#f5f5f5",
		sizeStep: 4,
	},
	algorithm: [antDtheme.darkAlgorithm],
};

export default theme;
