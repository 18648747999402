import { queryOptions } from "@tanstack/react-query";
import type ServiceLocator from "@services/locator/serviceLocator";

export const filterListQuery = (serviceLocator: ServiceLocator) => {
	const contentService = serviceLocator.get("contentService");
	return queryOptions({
		queryKey: ["filterList"],
		queryFn: () => contentService.getListOfFilters(),
		staleTime: 1000 * 60 * 60,
	});
};
