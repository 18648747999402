import { z } from "zod";

export const filterSchema = z.object({
	default: z.boolean(),
	group: z.string().nullish(),
	id: z.string(),
	query: z.string(),
	sectionTitle: z.string(),
	sort: z.string(),
	slug: z.string().nullish(),
	sortDirection: z.string(),
	type: z.string(),
	weight: z.coerce.number().default(0),
});

export type Filter = z.infer<typeof filterSchema>;
