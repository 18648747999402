import { z } from "zod";

export const bannerSchema = z.object({
	id: z.number(),
	image: z.string(),
	mobile_image: z.string(),
	msite: z.string(),
});

export const bannerArraySchema = z.array(bannerSchema);

export const bannerResponseSchema = z.object({
	message: z.string().nullish(),
	status: z.string(),
	data: z.object({
		banners: bannerArraySchema,
		extraBanner: bannerSchema.nullish(),
	}),
});

export type Banner = z.infer<typeof bannerSchema>;

export type BannerArray = z.infer<typeof bannerArraySchema>;
