import React, { Suspense } from "react";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import type { QueryClient } from "@tanstack/react-query";
import type ServiceLocator from "@services/locator/serviceLocator";
import MenuComponent from "@components/menu/MenuComponent";
import { Layout } from "antd";
import HeaderComponent from "@components/header/HeaderComponent";
import FooterComponent from "@components/footer/FooterComponent";
const { Content } = Layout;
const TanStackRouterDevtools =
	process.env.NODE_ENV === "production"
		? () => null // Render nothing in production
		: React.lazy(() =>
				// Lazy load in development
				import("@tanstack/router-devtools").then((res) => ({
					default: res.TanStackRouterDevtools,
					// For Embedded Mode
					// default: res.TanStackRouterDevtoolsPanel
				})),
			);

const ReactQueryDevtools =
	process.env.NODE_ENV === "production"
		? () => null // Render nothing in production
		: React.lazy(() =>
				import("@tanstack/react-query-devtools").then((d) => ({
					default: d.ReactQueryDevtools,
				})),
			);

export const Route = createRootRouteWithContext<{
	queryClient: QueryClient;
	serviceLocator: ServiceLocator;
}>()({
	component: RootComponent,
});

function RootComponent() {
	return (
		<>
			<HeaderComponent />
			<Layout>
				<MenuComponent />
				<Content className="overflow-x-hidden p-4 flex-col space-y-4">
					<Outlet />
					<Suspense>
						<TanStackRouterDevtools />
						<ReactQueryDevtools />
					</Suspense>
				</Content>
			</Layout>
			<FooterComponent />
		</>
	);
}
