import {
	HomeOutlined,
	InfoCircleOutlined,
	PlayCircleOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
const { Sider } = Layout;
import { Link, useLocation } from "@tanstack/react-router";

type MenuItem = Required<MenuProps>["items"][number];

function MenuComponent() {
	const { pathname } = useLocation();

	const selectedKey = pathname.split("/").filter(Boolean)[0] || "home";

	const items: MenuItem[] = [
		{
			key: "home",
			label: <Link to="/">Home</Link>,
			icon: <HomeOutlined />,
		},
		{
			key: "movies",
			label: <Link to="/movies">Movies</Link>,
			icon: <PlayCircleOutlined />,
		},
		{
			key: "about",
			label: <Link to="/about">About</Link>,
			icon: <InfoCircleOutlined />,
		},
	];

	return (
		<Sider className="sider">
			<Menu
				style={{ height: "100%" }}
				defaultSelectedKeys={[selectedKey]}
				mode="inline"
				items={items}
			/>
		</Sider>
	);
}

export default MenuComponent;
