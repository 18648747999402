import { z } from "zod";
import { movieSchema } from "./movieListSchema";

export const elasticResponseSchema = z.object({
	took: z.number(),
	timed_out: z.boolean(),
	_shards: z.object({
		total: z.number(),
		successful: z.number(),
		skipped: z.number(),
		failed: z.number(),
	}),
	hits: z.object({
		max_score: z.number().nullish(),
		total: z.object({
			value: z.number(),
			relation: z.string(),
		}),
		hits: z.array(
			z.object({
				_index: z.string(),
				_type: z.string(),
				_id: z.string(),
				_score: z.number().nullish(),
				_source: movieSchema,
				sort: z.array(z.number()),
			}),
		),
	}),
});
