import type { IAuthConfiguration } from "@services/auth/authTypes";
import type { IContentConfiguration } from "@services/content/contentTypes";
import type { IMonitorConfiguration } from "@services/monitor/monitorTypes";
import type { IConfigService } from "./configTypes";

export default class ConfigService implements IConfigService {
	public sentry: IMonitorConfiguration;
	public content: IContentConfiguration;
	public auth: IAuthConfiguration;

	constructor() {
		this.sentry = {
			dsn: import.meta.env.VITE_SENTRY_DSN,
			environment: import.meta.env.VITE_ENVIRONMENT || "development",
		};

		this.content = {
			apiUrl: import.meta.env.VITE_CONTENT_API_URL,
			elasticUrl: import.meta.env.VITE_ELASTIC_URL,
			tenantShortName: import.meta.env.VITE_TENANT_SHORT_NAME,
		};

		this.auth = {
			authUrl: import.meta.env.VITE_AUTH_URL,
			domain: import.meta.env.VITE_DOMAIN,
		};
	}
}

// Export the service for use in other parts of your application
export const configService = new ConfigService();
