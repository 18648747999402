import type ConfigService from "@services/config/configService";
import type { ILoggerService } from "./loggerTypes";

export default class LoggerService implements ILoggerService {
	dsn: string;
	environment: string;

	constructor(configService: ConfigService) {
		const { dsn, environment } = configService.sentry;
		this.dsn = dsn;
		this.environment = environment || "development";
	}

	public logEvent(eventName: string, contentId?: string) {
		console.log(`Logging event: ${eventName}, id: ${contentId}`);
		return new Promise((r) => {
			setTimeout(r, 10000);
			return true;
		});
	}
}
