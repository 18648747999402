import { z } from "zod";

export const movieSchema = z.object({
	title: z.string(),
	slug: z.string(),
	description: z.string(),
	trailer: z.string().nullish(),
	video: z.string().nullish(),
	isUpcoming: z.boolean(),
	is_licensed: z.boolean(),
	rank: z.number(),
	likes: z.number(),
	dislikes: z.number(),
	publishedDate: z.number(),
	publishedDateRank: z.string(),
	img: z.string(),
	videoTrailer: z.string(),
	site: z.object({
		siteID: z.number(),
		shortName: z.string(),
		nickName: z.string(),
		siteName: z.string(),
		upsell_id: z.string(),
		slug: z.string(),
	}),
	siteLogo: z.string(),
	tier: z.number(),
	type: z.string(),
	models: z.array(
		z.object({
			id: z.number(),
			name: z.string(),
		}),
	),
});

export const movieListSchema = z.array(movieSchema);

export type Movie = z.infer<typeof movieSchema> & { id: string };

export type MoviePageInfinite = {
	movies: Movie[];
	lastResultSort: number[];
};

export type MoviePage = {
	movies: Movie[];
	total: number;
};
