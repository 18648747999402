import { z } from "zod";

export const movieContentSchema = z.object({
	stream: z.string().nullish(),
	stream2: z
		.object({
			avc: z.object({
				dash: z.string(),
				hls: z.string(),
				fallback: z.string(),
			}),
			vp9: z.object({
				dash: z.string(),
				hls: z.string(),
				is4k: z.boolean(),
			}),
			vp9_mobile: z.object({
				dash: z.string(),
				hls: z.string(),
			}),
			av1: z.object({
				dash: z.string(),
				hls: z.string(),
			}),
			sprite: z.string(),
			timeline: z.string(),
			thumb: z.array(z.string()),
		})
		.nullish(),
	heat_timeline: z.array(z.number()).nullish(),
});

export const movieContentResponseSchema = z.object({
	message: z.string().nullish(),
	status: z.string(),
	data: movieContentSchema,
});

export type MovieContent = z.infer<typeof movieContentSchema>;
